<template>
  <div class="column">
    <q-table flat wrap-cells
      :data="cibles"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="rowsPerPageOptions"
      :pagination.sync="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort>
      <q-tr slot="body" slot-scope="props" :props="props"
        @click.native="$router.push({ name: 'cible-show', params: { id: props.row.id } })"
        class="cursor-pointer">
        <q-td v-for="col in props.cols" :key="col.name" :props="props" :auto-width="col.autoWidth">
          <q-icon v-if="col.name === 'statut' && col.value" :name="$getIcon(col.name, col.value)" size="18px" />
          <q-icon v-else-if="col.name === 'qualification' && col.value" :name="$getIcon(col.name, col.value)" size="18px" />
          <div v-else-if="col.value" v-html="col.value" />
        </q-td>
      </q-tr>
    </q-table>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CiblesList',

  meta () {
    return {
      title: 'Liste des cibles'
    }
  },

  data () {
    return {
      filter: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      cibles: 'cibles/getCibles',
      filters: 'cibles/getFilters',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    pagination: {
      ...mapGetters({ get: 'cibles/getPagination' }),
      ...mapMutations({ set: 'cibles/setPagination' })
    },

    columns () {
      if (this.cibles.length === 0) return []
      else {
        const fields = ['label', 'cibles', 'date', 'date_validation', 'statut', 'qualification']
        const sortable = ['label', 'date', 'statut', 'qualification']

        return fields.map(key => {
          let label = this.$t(`cibles.fields.${key}.label`)
          let align = 'left'
          let autoWidth = false
          let formatFn = val => val

          if (key === 'cibles') {
            label = this.$t(`cibles.fields.model.label`)
            formatFn = val => val.map(cible => {
              if (cible.agents.length) {
                return `${cible.agents[0]['PRENOM']} ${cible.agents[0]['NOMITV']}`
              } else if (cible.wallets.length) {
                return cible.wallets[0]['NOMPTF']
              } else if (cible.entities.length) {
                return cible.entities[0]['label']
              }
            }).filter((value, index, self) => !!value && self.indexOf(value) === index).join(', ')
          } else if (key === 'date' || key === 'date_validation' || key === 'created_at' || key === 'updated_at') {
            formatFn = val => this.$formatDate(val)
          } else if (key === 'statut' || key === 'qualification') {
            align = 'center'
          }

          return {
            name: key,
            align: align,
            label: label,
            field: key,
            format: formatFn,
            sortable: sortable.indexOf(key) >= 0,
            autoWidth: autoWidth
          }
        })
      }
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.onRequest({ pagination: this.pagination })
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) {
      this.onRequest({ pagination: this.pagination })
    }
  },

  methods: {
    onRequest ({ pagination, filter }) {
      this.$store.commit('cibles/setPagination', pagination)
      this.loading = true

      let params = { ...pagination }
      this.filters.forEach(filter => {
        if (filter.type === 'group') {
          const { items } = filter
          items.filter(item => item.value).forEach(item => {
            params[`filters[${filter.id}][${item.id}]`] = item.value
          })
        } else {
          params[filter.id] = filter.value
        }
      })

      this.$store.dispatch('cibles/fetchCibles', params)
        .finally(() => { this.loading = false })
    }
  }
}
</script>
